import { TLangCode } from "_types/common";
import jwtDecode from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { I18nContext, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { DASHBOARD_REDIRECTION_URL, isLocalEnv } from "utils/common";

const COOKIE_OPTIONS = {
  "maxAge": 900,
  "domain": isLocalEnv ? `localhost` : `.estatelogs.com`,
  "secure": !isLocalEnv,
  "path": `/`
};

export type TAppHook = {
  onLangSelect: (language: TLangCode) => void;
  language: TLangCode;
  setTokenCookie: (token: string | null) => void;
  getTokenCookie: () => string | null;
  token: string;
  redirectionURL: string;
};

export default function useApp(): TAppHook {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    `token`
  ]);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [_lng, setLng] = useState(``);
  const { "i18n": { language, changeLanguage } } = useContext(I18nContext) || {};
  const params = new URLSearchParams(search);
  const urlToken = params.get(`token`);
  const urlRedirect = params.get(`redirect`);
  const urlLng = params.get(`lng`);

  const [token] = useState(urlToken || ``);
  const [redirectionURL, setRedirectionURL] = useState(urlRedirect || DASHBOARD_REDIRECTION_URL);

  const { "redirect": tokenRedirect = `` } = urlToken
    ? jwtDecode<{ redirect: string; }>(urlToken || ``)
    : {};


  const setTokenCookie = (token: string | null) => {
    setCookie(`token`, token, COOKIE_OPTIONS);
  };

  const getTokenCookie = (): string | null => cookies?.token || null;

  const onLangSelect = (language: TLangCode) => {
    setLng(language);
    changeLanguage(language);
  };

  useEffect(() => {
    if (urlRedirect) {
      urlLng && changeLanguage(urlLng || `eng`);
      navigate(pathname, { "replace": true });

      return;
    }

    tokenRedirect && setRedirectionURL(tokenRedirect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `Estate Logs ${t(`LBL_AUTHENTICATION`)}`;
  }, [language, t]);

  return {
    onLangSelect,
    "language": language as TLangCode,
    setTokenCookie,
    getTokenCookie,
    token,
    redirectionURL
  };
}
